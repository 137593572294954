.containerPartner {
  padding: 50px;

  .topContainer {
    h3 {
      margin: 0 auto;
      width: max-content;
      padding: 7px 25px;
      color: #fff;
      background-color: #2a71a8;
      border-radius: 15px;
      box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
      -webkit-box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
      -moz-box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
    }
  }
}

// .containerSwiperPartner {
//   border: 2px solid red;
// }

.item-partner {
  border: 1px solid #095591;
  padding: 15px 0;
  border-radius: 75px 10px;
  background-color: #095591;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 100%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 15px 0;
    border-radius: 75px 10px;
    background-color: rgba(0, 0, 0, 0.247);
    opacity: 1;
    transition: opacity 0.3s;
  }

  &:hover::before {
    opacity: 0;
    padding: 15px 0;
    border-radius: 75px 10px;
  }
}

.containerPartner h3 {
  font-size: 35px;
  color: #095591;
}

.boxpartner {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.line {
  width: 170px;
  height: 2px;
  background-color: #095591;
}

.mySwiper3 {
  width: 100%;
  height: 100%;
}

@media (min-width: 14in) {
  .containerPartner {
    margin: 0 auto;
    max-width: 14in;
  }
}

@media screen and (max-width: 860px) {
  .containerPartner {
    padding: 0 10px 50px 10px;
  }

  .item-partner {
    border: 1px solid #095591;
    padding: 5px;
    border-radius: 25px 7px;
    background-color: #095591;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 135%;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 5px;
      border-radius: 25px 7px;
      background-color: rgba(0, 0, 0, 0.247);
      opacity: 1;
      transition: opacity 0.3s;
    }

    &:hover::before {
      opacity: 0;
      padding: 5px;
      border-radius: 25px 7px;
    }
  }
}
