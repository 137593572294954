@media screen and (max-width: 850px) {
  .col-profile {
    display: flex;
    flex-direction: column;
  }

  .profile-box {
    align-self: center;
    width: 50%;
    margin-bottom: 15px;
  }

  .profile-box img {
    width: 90%;
    height: 100%;
  }

  .mx-4 {
    display: flex;
    flex-direction: column;

    .nameDivision {
      align-self: center;
      text-align: center;
    }
  }
}

@media screen and (max-width: 650px) {
  .col-profile {
    display: flex;
    flex-direction: column;
  }

  .profile-box {
    align-self: center;
    width: 80%;
    margin-bottom: 15px;
  }

  .profile-box img {
    width: 90%;
    height: 95%;
  }

  .mx-4 {
    display: flex;
    flex-direction: column;

    .nameDivision {
      align-self: center;
      text-align: center;
    }
  }
}
