@font-face {
  font-family: 'Helvetica';
  src: url('../src/assets/font/Helvetica.ttf') format('truetype');
}
* {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  padding: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3273af;
  border-radius: 10px;
  border: 3px solid #e6e6e6;
}

::-webkit-scrollbar-thumb:hover {
  background: #3c92e2;
}
