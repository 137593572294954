.containerAnggaran {
  padding: 0 70px 0 70px;
  overflow: hidden;
}

.anggaranTop h3,
.anggaranBottom h3 {
  font-weight: 600;
  color: #114f8c;
}

@media screen and (max-width: 860px) {
  .containerAnggaran {
    width: 100%;
    padding: 25px;
  }
}

@media (min-width: 14in) {
  .containerAnggaran {
    margin: 0 auto;
    max-width: 14in;
  }
}
