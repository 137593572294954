.containerPrinsip {
  padding: 0 70px 0 70px;
}

.contentPrinsip {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 100px;
  box-shadow: 0px 0px 20px 0px rgba(2, 2, 2, 0.253);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(2, 2, 2, 0.253);
  -moz-box-shadow: 0px 0px 20px 0px rgba(2, 2, 2, 0.253);
  height: max-content;
  padding: 75px;
  border-radius: 100px;

  h4 {
    font-weight: 600;
  }

  h5 {
    font-weight: bold;
  }

  .tableContainer {
    th {
      border: 1px solid black;
      padding: 10px;
      background-color: #e7e7e7;
      text-align: center;
    }
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  .tableContainerSekretaris {
    th {
      border: 1px solid black;
      padding: 10px;
      background-color: #e7e7e7;
      width: 30%;
    }
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  .tableContainerAudit {
    th {
      border: 1px solid black;
      padding: 10px;
      background-color: #e7e7e7;
      width: 30%;
    }
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  .listAlpha {
    list-style-type: lower-alpha;
  }

  .listDecimal {
    list-style-type: decimal;

    h6 {
      font-weight: bold;

      span {
        font-weight: 400;
      }
    }
  }

  .listDecimal li::marker {
    font-weight: bold;
  }

  .tableProspektus {
    th {
      border: 1px solid black;
      padding: 10px;
      background-color: #e7e7e7;
      width: 30%;
    }
    td {
      border: 1px solid black;
      padding: 5px;
    }
  }

  .containerDokumentasiCsr {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      width: 55%;
      margin: 10px;
    }
  }
}

.contentStruktur {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 600;
    align-self: flex-start;
  }

  p {
    align-self: flex-start;
  }

  img {
    width: 75%;
  }
}

@media screen and (max-width: 860px) {
  .containerPrinsip {
    padding: 0 15px;
  }

  .contentPrinsip {
    border-radius: 50px;
    padding: 50px 25px;
  }

  .imgTatakelola {
    width: 100%;
  }

  table {
    font-size: 0.6rem;
  }
}

@media (min-width: 14in) {
  .containerPrinsip {
    margin: 0 auto;
    max-width: 14in;
  }
}
