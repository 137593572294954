.content-text {
  color: white;
  font-weight: bold;
}

.contentNews {
  background-image: url('../../assets/home/newsheader.png');
  height: 75vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.news-content {
  height: 75vh;
  width: 100%;
}

.bottomNews {
  height: max-content;
  padding: 50px 80px;

  .filter-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;

    .autoComplete {
      width: 200px;
    }

    .filterTop {
      display: flex;
      gap: 15px;

      h3 {
        color: rgb(71, 71, 71);
      }
    }

    .page {
      color: gray;
      font-weight: 600;
      font-size: 1.1rem;
    }
  }

  .middle {
    .bodyNews {
      display: flex;
      gap: 50px;
      margin: 30px 25px;

      img {
        width: 50%;
        border-radius: 10px;
        max-height: 50%;
      }
    }

    .desc {
      width: 75%;

      h5 {
        margin-bottom: 25px;
      }

      hr {
        width: 45%;
      }

      a {
        text-decoration: none;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 0;
          height: 3px;
          background-color: #56a5ff;
          transition: width 0.2s ease-in-out;
        }

        &:hover::after {
          width: 100%;
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 25px;
  }
}

.loading-spinner-news {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  height: 90vh;
}

@media screen and (max-width: 860px) {
  .bottomNews {
    padding: 0;

    .filter-search {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .autoComplete {
        width: 150px;
      }

      .filterTop {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .page {
        color: gray;
        font-weight: 600;
        font-size: 1.1rem;
      }
    }

    .middle {
      .bodyNews {
        display: flex;
        flex-direction: column;
        gap: 50px;

        img {
          width: 100%;
          margin-bottom: -25px;
        }
      }

      .desc {
        width: 100%;

        h5 {
          margin-bottom: 5px;
        }

        hr {
          width: 45%;
        }

        a {
          text-decoration: none;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 0;
            height: 3px;
            background-color: #56a5ff;
            transition: width 0.2s ease-in-out;
          }

          &:hover::after {
            width: 100%;
          }
        }
      }
    }

    .pagination {
      display: flex;
      justify-content: end;
      align-items: center;
      padding-top: 25px;
    }
  }
}

@media (min-width: 14in) {
  .bottomNews {
    margin: 0 auto;
    max-width: 14in;
  }
}
