.containerStruktur {
  padding: 0 70px 0 70px;
  height: max-content;
  margin-bottom: 75px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.containerTop {
  h3 {
    font-weight: 600;
    color: #114f8c;
  }

  p {
    font-weight: bold;
  }
}

.containerMiddle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 90%;
  }
}

.containerBottom {
  margin-top: 50px;
  margin-bottom: 50px;

  h6 {
    font-weight: 600;
  }

  button {
    border: none;
    background: none;
    background-color: #1e67b1;
    color: #fff;
    border-radius: 25px;
    padding: 3px 13px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #327cc7;
    }
  }
}

@media (min-width: 14in) {
  .containerStruktur {
    margin: 0 auto;
    max-width: 14in;
  }

  .containerBottom {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 860px) {
  .containerStruktur {
    padding: 0 15px;
  }

  .containerMiddle {
    img {
      width: 100%;
    }
  }
}
