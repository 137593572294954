.content-text {
  color: white;
  font-weight: bold;
}

.contentNewsDetail {
  background-image: url('../../../assets/home/newsheader.png');
  height: 75vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.newsDetail-content {
  height: 75vh;
  width: 100%;
}

.bottomNewsDetail {
  height: max-content;
  padding: 50px 125px;

  .wp-block-heading {
    font-size: 20px;
    font-weight: 600;
  }

  img {
    width: 100%;
    margin-bottom: 25px;
    border-radius: 5px;
  }

  .shareContent {
    display: flex;
    align-items: end;
    flex-direction: column;

    .shareIcon {
      display: flex;
      justify-content: end;
      gap: 10px;
      margin-top: -5px;
    }

    .linkIcon {
      text-decoration: none;
      width: 3%;
      height: 3%;

      .imgIcon {
        margin-top: -1px;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;

        &:hover {
          box-shadow: 0px 0px 6px 0px rgba(7, 58, 136, 0.56);
          -webkit-box-shadow: 0px 0px 6px 0px rgba(7, 58, 136, 0.56);
          -moz-box-shadow: 0px 0px 6px 0px rgba(7, 58, 136, 0.56);
        }
      }
    }
  }

  h1 {
    font-weight: 600;
    text-align: center;
  }

  h5 {
    font-weight: 600;
    color: rgb(80, 80, 80);
    margin-bottom: 25px;
  }

  h6 {
    font-weight: 600;
    margin-top: 15px;
  }
}

.containerChillImg {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  .chillImg {
    width: 45%;
    height: 40%;
    border-radius: 5px;
  }
}

.loading-spinner-news {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

.wp-block-group {
  display: flex;
  gap: 15px;

  img {
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 860px) {
  .bottomNewsDetail {
    padding: 25px;

    .heroImageNews {
      max-height: 30vh;
    }

    .shareContent {
      display: flex;
      align-items: end;
      flex-direction: column;

      .shareIcon {
        display: flex;
        justify-content: end;
        gap: 10px;
        margin-top: -5px;
      }

      .linkIcon {
        text-decoration: none;
        width: 7%;
        height: 7%;

        .imgIcon {
          margin-top: -1px;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          transition: all 0.2s ease-in-out;

          &:hover {
            box-shadow: 0px 0px 6px 0px rgba(7, 58, 136, 0.56);
            -webkit-box-shadow: 0px 0px 6px 0px rgba(7, 58, 136, 0.56);
            -moz-box-shadow: 0px 0px 6px 0px rgba(7, 58, 136, 0.56);
          }
        }
      }
    }
  }
}

@media (min-width: 14in) {
  .bottomNewsDetail {
    margin: 0 auto;
    max-width: 14in;
  }
}
