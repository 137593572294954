.containerSwiper {
  height: max-content;
  padding: 25px 50px;
  margin-top: 125px;
  margin-bottom: 25px;
}

.slideShow h3 {
  font-weight: bold;
  color: #0a5493;
}

.mySwiper .swiper-slide {
  flex-shrink: 0;
  left: 25%;
}

.slideShow {
  text-align: center;
}

.imgSwiper {
  height: 400px;
  width: 90%;
  border-radius: 5px;
  transition: transform 0.3s, border-color 0.3s, cursor 0.3s;
}

.imgSwiper:hover {
  cursor: pointer;
  border-radius: 5px;
  border-color: black;
  transform: scale(1.1);
}

.swiper-button-prev {
  background-color: #e9e9e9b0;
  padding: 30px 25px;
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  margin-left: 15px;
}

.swiper-button-next {
  background-color: #e9e9e9b0;
  padding: 30px 25px;
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  margin-right: 15px;
}

.swiper-button-prev:hover {
  margin-left: 0;
}

.swiper-button-next:hover {
  margin-right: 0;
}

.modalSwiper {
  top: 25px;

  .imgModal {
    width: 100%;
    border-radius: 5px;
    margin: 5px 0 20px 0;
  }

  h6 {
    font-weight: 600;
  }

  .containerChillImg {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;

    .chillImg {
      width: 25%;
      border-radius: 5px;
    }
  }
}

@media screen and (max-width: 860px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .imgSwiper {
    height: 450px;
  }

  .modalSwiper {
    .containerChillImg {
      .chillImg {
        width: 45%;
      }
    }
  }

  .mySwiper .swiper-slide {
    flex-shrink: 0;
    left: 0%;
  }
}

@media (min-width: 14in) {
  .slideShow {
    margin: 0 auto;
    max-width: 14in;
  }
}
