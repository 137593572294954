.containerContact {
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 25px;
  height: max-content;
  padding: 0 100px 100px 100px;
}

.location {
  min-height: 30vh;
  width: 25%;
  border-radius: 20px;
  padding: 25px;
  color: #114f8c;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  perspective: 1000px;
  position: relative;

  .icon {
    font-size: 5rem;
    margin-bottom: 10px;
    color: #114f8c;
  }

  p {
    font-weight: 600;
  }
}

.phone {
  min-height: 30vh;
  width: 25%;
  border-radius: 20px;
  padding: 25px;
  color: #114f8c;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  perspective: 1000px;
  position: relative;

  .icon {
    font-size: 5rem;
    margin-bottom: 10px;
    color: #114f8c;
  }

  p {
    font-weight: 600;
  }
}

.email {
  min-height: 30vh;
  width: 25%;
  border-radius: 20px;
  padding: 25px;
  color: #114f8c;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  perspective: 1000px;
  position: relative;

  .icon {
    font-size: 5rem;
    margin-bottom: 10px;
    color: #114f8c;
  }

  p {
    font-weight: 600;
  }
}

.location .front,
.location .back,
.phone .front,
.phone .back,
.email .front,
.email .back {
  transition: all ease-in-out 0.3s;
  position: absolute;
  width: 90%;
  margin: 0 auto;
  margin-top: 15px;

  h1 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.location:hover,
.phone:hover,
.email:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 860px) {
  .containerContact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .location,
  .phone,
  .email {
    width: 70%;
  }
}

@media (min-width: 14in) {
  .containerContact {
    margin: 0 auto;
    max-width: 14in;
  }
}

/* Flip effect for location */
.location .front,
.location .back,
.phone .front,
.phone .back,
.email .front,
.email .back {
  backface-visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.location .back,
.phone .back,
.email .back {
  transform: rotateY(180deg);
}

.location,
.phone,
.email {
  transform-style: preserve-3d;
}

.location:hover .front,
.phone:hover .front,
.email:hover .front {
  transform: rotateY(180deg);
}

.location:hover .back,
.phone:hover .back,
.email:hover .back {
  transform: rotateY(0deg);
}
