.containerManajemen {
  padding: 0 70px 0 70px;
}

.boxManajemen {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;

  .lineBottom {
    width: 90%;
    margin: 0 auto;
    height: 2px;
    background-color: #000000;
    border: none;
  }
}

.contentManajemen {
  height: max-content;
  padding: 75px 50px;
  border-bottom: 2px solid #333;
  width: 90%;
  display: flex;
  align-items: center;
  text-align: start;
  gap: 50px;

  &:last-child {
    border-bottom: none;
  }

  h5 {
    font-weight: 600;
    font-size: 1.5rem;
    align-self: flex-start;
  }

  ul,
  p {
    align-self: flex-start;
  }

  .contentLeft {
    flex: 1.5;
  }
}

.pdfContainerNew {
  width: 80%;
  display: flex;
  padding: 5%;
  gap: 25px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px 0px rgba(2, 2, 2, 0.253);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(2, 2, 2, 0.253);
  -moz-box-shadow: 0px 0px 10px 0px rgba(2, 2, 2, 0.253);

  img {
    width: 50%;
  }

  .descContainer {
    padding: 15px;
    width: 100%;

    .top {
      h2 {
        font-weight: 600;
      }
    }

    a {
      text-decoration: none;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: #56a5ff;
        transition: width 0.2s ease-in-out;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 860px) {
  .contentRight {
    background-color: #333;
    border-radius: 10px;
    height: 55vh;
    flex: 0.7;
  }
}

@media screen and (max-width: 860px) {
  .containerManajemen {
    padding: 15px;
  }

  .contentRight {
    background-color: #333;
    border-radius: 10px;
    height: 55vh;
    width: 100%;
  }

  .contentManajemen {
    border-radius: 25px;
    flex-direction: column;
    padding: 70px 25px;
  }

  .pdfContainerNew {
    padding-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90%;

    img {
      width: 70%;
    }
  }
}

@media (min-width: 14in) {
  .containerManajemen {
    margin: 0 auto;
    max-width: 14in;
  }
}
