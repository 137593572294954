.contentGcgDetail {
  background-image: url('../../../../../assets/home/governancebanner.png');
  height: 75vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.content-text {
  color: white;
  font-weight: bold;
}

.textHero {
  text-shadow: 0px 0px 15px rgb(252, 252, 252);
  text-align: center;
  font-weight: 600;
}

.bottomMutuDetail {
  height: max-content;
  padding: 50px 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 25px;
  text-align: center;

  h1 {
    font-weight: 600;
  }

  img {
    width: 50%;
  }
}

@media screen and (max-width: 860px) {
  .bottomMutuDetail {
    padding: 15px;
    text-align: center;
  }
}
