.containerFaq {
  height: max-content;
  padding: 0 100px 0 100px;

  .containerTop {
    h1 {
      font-weight: 600;
      color: grey;
    }
  }

  .containerAccordion {
    margin-top: 50px;
  }
}

@media screen and (max-width: 860px) {
  .containerFaq {
    height: 100vh;
    padding: 15px;
  }
}

@media (min-width: 14in) {
  .containerFaq {
    margin: 0 auto;
    max-width: 14in;
  }
}
