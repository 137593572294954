.content-text {
  color: white;
  font-weight: bold;
}

.contentInvestor {
  background-image: url('../../assets/home/investorbanner.png');
  height: 75vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.text-center-hero {
  text-shadow: 0px 0px 15px rgb(252, 252, 252);
  text-align: center;
}

.content-text h1 {
  font-size: 4.5rem;
}

.laporan-content {
  height: 75vh;
  width: 100%;
}

.content2 {
  position: relative;
  z-index: 2;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: white;
  width: 100%;
  height: 30vh;
}

.containerAbout {
  display: flex;
  justify-content: center;
  gap: 25px;
}

.isi-content-investor {
  margin-top: 100px;
}

.buttonsInvestor {
  padding-top: 50px;
  height: 100vh;
  background-color: white;
}

.buttonsInvestor .btnMenu {
  width: 155px;
  padding: 5px 5px 7px 5px;
  color: white;
  border-radius: 22px;
  background-color: #114f8c;
  border: 2px solid #4679ac;
  transition: all 0.2s ease-in-out;
}

.buttonsInvestor .btnMenu:hover {
  box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
  -webkit-box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
  -moz-box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
}

.btnMenu {
  background-color: white;
  border: 1px solid #ccc;
  color: #333;
  padding: 10px 20px;
  cursor: pointer;
}

.btnMenu.clicked {
  background-color: #2e6bbb;
  box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
  -webkit-box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
  -moz-box-shadow: 0px 0px 6px 1px rgba(7, 58, 136, 0.56);
}

/* Responsive */

@media screen and (max-width: 860px) {
  .containerAbout {
    display: flex;
    flex-wrap: wrap;
  }

  .containerAbout button {
    margin-bottom: 20px;
  }
}
