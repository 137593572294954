.mainContainerOrganisasi {
  padding: 0 15px 0 15px;
  display: flex;
}

.menuStrukturOrganisasi {
  position: sticky;
  top: 150px;
  display: flex;
  color: white;
  margin-left: 50px;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 16px;
  background-color: #0a5493;
  border-radius: 22px;
  height: max-content;
  width: 35vh;
  padding: 15px;
  gap: 10px;
}

.menuStrukturOrganisasi div:hover {
  background-color: #4081b9;
  cursor: pointer;
}

.menuStrukturOrganisasi div {
  padding-right: 3%;
  height: auto;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.menuStrukturOrganisasi div p {
  margin-left: 25px;
  margin-top: 10px;
}

.outletOrganization {
  height: max-content;
  margin-left: 60px;
  width: 65%;
}

.clicked {
  background-color: #4081b9;
  border-radius: 10px;
}

.profile-box {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 30%;
  height: 18rem;
  padding-top: 25px;
  background-color: rgb(221, 221, 221);
}

.profile-box img {
  width: 85%;
}

.footer {
  margin-top: 50px;
}

.mx-4 h6 {
  font-weight: 600;
}

.btnOrganisasi {
  padding: 15px;
}

/* Responsive */

@media screen and (max-width: 860px) {
  .mainContainerOrganisasi {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 25px;
    overflow-x: hidden;
  }

  .menuStrukturOrganisasi {
    flex-direction: row;
    top: 0;
    border-radius: 15px;
    height: max-content;
    padding: 15px;
    width: 100%;
    margin: 0;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    position: relative;

    .btnOrganisasi {
      padding: 10px;
      font-size: 15px;
      flex: 1;
    }
  }

  .outletOrganization {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .profile-box {
    height: 22rem;
  }
}

@media (min-width: 14in) {
  .mainContainerOrganisasi {
    margin: 0 auto;
    max-width: 14in;
  }
}
