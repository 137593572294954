.homeMainContainer {
  overflow-x: hidden;
  background-color: #f4f9fc;
}

.containerMenuProduct {
  // background-color: #1b66a8ea;
  border-top-right-radius: 15rem;
  border-bottom-left-radius: 15rem;
  // padding: 50px 0 50px 0;
}

.content-title-home {
  background-image: url('../../assets/home/heroimg.png');
  height: 100vh;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Arial, Helvetica, sans-serif;
}

.content-title-home h1 {
  font-size: 3.5rem;
  font-weight: 900;
  text-align: center;
  color: rgba(255, 255, 255, 0.979);
  text-shadow: 0 0 20px rgb(80, 170, 255), 0 0 40px rgb(80, 170, 255);
}

.perseroanHome {
  display: flex;
  justify-content: center;
  margin-top: -100px;
  position: absolute;
  width: 100%;
}

.isiContent {
  height: 50vh;
  width: 95%;
  background-color: #ffffff96;
  border-radius: 25px;

  h1 {
    text-align: center;
    margin-top: 25px;
  }
}

@media (max-width: 960px) {
  .content-title-home h1 {
    font-size: 2rem;
  }
}
