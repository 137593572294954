.rupsTop {
  h2 {
    font-weight: 600;
    color: rgb(83, 83, 83);
  }

  h4 {
    color: gray;
  }
}

.mainContainerRups {
  overflow: hidden;
}
