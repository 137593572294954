// Variables
$primary-color: #095493;
$secondary-color: #1b66a8;
$text-color: #fff;
$font-family: Verdana, Geneva, Tahoma, sans-serif;

// Mixins for common styles
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

// Container styles
.containerTestimoni {
  @include flex-column;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  width: 100%;
  max-width: 1600px;
  margin: -80px auto 0;
  background-color: $primary-color;
  position: relative;
  overflow: hidden;
  padding: 80px 80px 150px 80px;
}

// Header styles
.headersTestimoni {
  @include flex-column;
  justify-content: center;
  text-align: center;
  padding: clamp(15px, 3vw, 25px);
  border-radius: 25px;
  font-family: $font-family;
  color: $text-color;
  margin-bottom: clamp(20px, 4vw, 40px);
  width: 100%;
  max-width: 800px;

  h3 {
    font-weight: 600;
    font-size: clamp(1.5rem, 2.5vw, 2rem);
    margin-bottom: 0.5em;
  }

  h4 {
    font-size: clamp(1.2rem, 2vw, 1.5rem);
  }
}

// Swiper styles
.mySwiper2 {
  width: min(90%, 1200px);
  border-radius: 15px;
  height: auto;
  margin: 0 auto;
}

// Card styles
.sliderCard {
  @include flex-center;
  padding: clamp(15px, 3vw, 25px);
  gap: clamp(10px, 2vw, 15px);
  background-color: $secondary-color;
  border-radius: 15px;
  min-height: 300px;
  height: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 15px;
  }
}

// Top card section
.topCard {
  @include flex-column;
  align-items: center;
  gap: 15px;
  width: 25%;
  color: $text-color;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }

  .imgWrapper {
    width: 100%;
    max-width: clamp(150px, 20vw, 200px);
    @include flex-center;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .identity {
    @include flex-column;
    justify-content: center;
    text-align: center;
    width: 100%;

    h5 {
      font-weight: 600;
      font-size: clamp(1rem, 1.5vw, 1.25rem);
      margin-bottom: 5px;
    }

    p {
      font-style: italic;
      font-size: clamp(0.875rem, 1.2vw, 1rem);
    }
  }
}

// Divider line
.borderLineTesti {
  border: 1px solid $text-color;
  height: clamp(150px, 25vh, 250px);
  margin: 0 clamp(15px, 3%, 30px);

  @media (max-width: 768px) {
    display: none;
  }
}

// Bottom card section
.bottomCard {
  width: 75%;
  color: $text-color;
  @include flex-column;
  justify-content: center;
  padding: clamp(10px, 2vw, 20px);
  overflow-y: auto;
  max-height: 400px;

  @media (max-width: 768px) {
    width: 100%;
    max-height: none;
  }

  p {
    font-style: italic;
    font-size: clamp(0.875rem, 1.2vw, 1rem);
    line-height: 1.6;
    text-align: center;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}

// Additional responsive adjustments
@media screen and (max-width: 480px) {
  .containerTestimoni {
    padding: 10px 10px 50px 10px;
    margin-top: -10px;
  }

  .headersTestimoni {
    padding: 10px;
  }

  .mySwiper2 {
    width: 95%;
  }
}

// Additional responsive adjustments
@media screen and (max-width: 780px) {
  .containerTestimoni {
    padding: 10px 10px 50px 10px;
    margin-top: -10px;
  }

  .headersTestimoni {
    padding: 10px;
  }

  .mySwiper2 {
    width: 95%;
  }
}

// Custom scrollbar for the bottom card
.bottomCard {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
  }
}
