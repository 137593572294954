.containerRegulasi {
  height: max-content;
  padding: 0 100px 0 100px;

  .containerTop {
    h2 {
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 860px) {
  .containerRegulasi {
    height: 120vh;
    padding: 15px;
  }
}

@media (min-width: 14in) {
  .containerRegulasi {
    margin: 0 auto;
    max-width: 14in;
  }
}
