.kalenderTop {
  h2 {
    font-weight: 600;
    color: gray;
  }
}

.mainContainerKalender {
  overflow: hidden;
}
