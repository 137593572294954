.containerMenuItem {
  padding: 100px 0 50px 0;
}

.item-home {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-evenly;
}

.item-home p {
  color: #fff;
  font-weight: bold;
}

.boxitem-home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxitem-home img {
  width: 120%;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.842);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.842);
  -moz-box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.842);
  border-radius: 16px;
}

.boxitem-home img:hover {
  transform: scale(1.1);
}

.text-center {
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 960px) {
  .containerMenuItem {
    margin-top: 50px;
  }

  .boxitem-home img {
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all ease-in-out 0.2s;
  }
}

@media (min-width: 14in) {
  .containerMenuItem {
    margin: 0 auto;
    max-width: 14in;
  }
}
