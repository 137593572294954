.react-pdf__Page__textContent {
  display: none;
}

.containerTahunan {
  overflow: hidden;
}

.pdfContainer {
  height: 750px;
  overflow: hidden;
}

.pdfContainer p {
  font-size: 14px;
  color: #333;
}

.btnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnPdf {
  display: flex;
  gap: 5px;
}

.btnContainer button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.btnContainer button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media screen and (max-width: 860px) {
  .containerTahunan {
    border-left: none;
    height: 120vh;
  }

  .btnContainer button {
    padding: 0;
  }
}
