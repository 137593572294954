.containerNotfound {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  background-color: #d4d4d4;
}

.containerLeft {
  width: 35%;
}

.containerRight {
  h2 {
    font-size: 1.5rem;
    font-weight: 600;
  }

  h3 {
    font-size: 1.3rem;
  }

  button {
    border: none;
    background-color: #007dfe;
    color: #fff;
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 1rem;
    font-weight: 600;
    transition: all ease-in-out 0.2s;
    cursor: pointer;

    &:hover {
      background-color: #41a0ff;
    }
  }
}
