.containerAwards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 70vh;
  gap: 25px;
  padding: 0 70px 0 70px;
  height: max-content;
  flex-wrap: wrap;
}

.penghargaan {
  border-radius: 50%;
  background-color: #0a5393;
  padding: 15px;
  transition: all ease-in-out 0.3s;

  &:hover {
    cursor: pointer;
    background-color: #187bd3;
  }

  img {
    width: 100%;
  }
}

@media screen and (max-width: 860px) {
  .containerAwards {
    flex-direction: column;
  }
}

@media (min-width: 14in) {
  .containerPenghargaan {
    margin: 0 auto;
    max-width: 14in;
  }
}
