.containerProduk {
  padding: 75px 0 0 0;
  text-align: center;

  h3 {
    font-weight: bold;
    color: #1b66a8ea;
  }

  p {
    color: #1b66a8ea;
  }
}

.tachyon {
  margin-right: 10px;
  background-image: url('../../../assets/home/bg-nethome.jpg');
  background-size: cover;
  background-position: center;
  height: 200px;
  position: relative;
  left: 50px;
  margin-top: -10px;
  background-color: #e9e9e9;
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
  border-top-left-radius: 22px;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  cursor: pointer;
}

.tachyon2 {
  background-image: url('../../../assets/home/bg-tachyon.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  margin-left: 10px;
  height: 200px;
  right: 90px;
  clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-top-left-radius: 22px;
  cursor: pointer;
}

/* .tachyon2 img{
      background-color: #e9e9e9;
  }
  
  .tachyon  img{
      padding: 3%;
      background-color: #e9e9e9;
  } */

.imgContainer {
  width: 85%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.568);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13vh;

  .img-fluid {
    width: 100%;
    height: 8vh;
  }
}

.imgContainer2 {
  width: 125%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.568);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13vh;

  .img-fluid {
    width: 100%;
    height: 10vh;
  }
}

.mainImgContainer {
  padding: 10px;
}

.secondImgContainer1 {
  margin-left: 100px;
}

.secondImgContainer2 {
  margin-left: 200px;
}

@media (max-width: 860px) {
  .mainImgContainer {
    flex-direction: column;
    gap: 15px;
  }

  .tachyon,
  .tachyon2 {
    flex: none;
  }

  .tachyon {
    width: 100%;
    background-size: cover;
    height: 200px;
    position: relative;
    left: 0;
    background-color: #e9e9e9;
    clip-path: none;
    border-radius: 20px;
  }

  .tachyon2 {
    width: 100%;
    background-size: cover;
    position: relative;
    margin-left: 0;
    height: 200px;
    right: 0;
    clip-path: none;
    border-radius: 20px;
  }

  .imgContainer {
    background-color: #f3f3f3c5;
    padding: 20px;
    border-radius: 5px;
    width: 100%;
  }

  .imgContainer2 {
    background-color: #f3f3f3c5;
    padding: 20px;
    border-radius: 5px;
    width: 150%;
  }

  .secondImgContainer1 {
    margin-left: 25%;
  }

  .secondImgContainer2 {
    margin-left: 25%;
  }
}

@media (min-width: 14in) {
  .containerProduk {
    margin: 0 auto;
    max-width: 14in;
  }
}
