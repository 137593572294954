.mainContainerSaham {
  padding: 0 15px 0 15px;
  display: flex;
}

.menuStrukturSaham {
  position: sticky;
  top: 150px;
  display: flex;
  color: white;
  margin-left: 50px;
  justify-content: space-evenly;
  flex-direction: column;
  font-size: 16px;
  background-color: #0a5493;
  border-radius: 22px;
  height: max-content;
  width: 40vh;
  padding: 15px;
}

.menuStrukturSaham div:hover {
  background-color: #4081b9;
  cursor: pointer;
}

.menuStrukturSaham div {
  padding-right: 3%;
  height: auto;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}

.menuStrukturSaham div p {
  margin-left: 25px;
  margin-top: 10px;
}

.outletStock {
  height: max-content;
  margin-left: 60px;
  width: 65%;
}

.btnStock {
  padding: 15px;
}

.choose {
  background-color: #4081b9;
  border-radius: 10px;
}

.footer {
  margin-top: 50px;
}

/* Responsive */

@media screen and (max-width: 860px) {
  .mainContainerSaham {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 25px;
  }

  .menuStrukturSaham {
    flex-direction: row;
    top: 0;
    border-radius: 15px;
    height: max-content;
    padding: 15px;
    width: 100%;
    margin: 0;
    gap: 5px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .btnStock {
      padding: 10px;
      font-size: 15px;
    }
  }

  .outletStock {
    width: 100%;
    margin-left: 0;
    padding: 0;
  }
}

@media (min-width: 14in) {
  .mainContainerSaham {
    margin: 0 auto;
    max-width: 14in;
  }
}
