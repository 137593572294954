.containerProfile {
  padding: 0 65px 0 65px;
}

.company-profile-text {
  color: white;
  background-color: #0a5493;
  border-radius: 50px;
  padding: 25px;
}

.containerLi {
  width: 40%;
  display: flex;

  .singkatan {
    width: 30%;
  }
}

.company-profile-text .text-center {
  margin-bottom: 25px;
}

.content-key-features {
  border-top: 1px solid black;
  text-align: start;
  padding: 2%;
  border-bottom: 1px solid black;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.key-features {
  margin-top: 100px;
}

.milestoneContainer {
  margin-top: 50px;
  height: 30vh;
}

.visi {
  border-right: 1px solid black;
  padding: 3%;
  text-align: start;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.misi {
  text-align: start;
  padding: 3%;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.content-visi-misi {
  padding-top: 2%;
  padding-bottom: 2%;
  border-bottom: 1px solid black;
}

/* Responsive */

@media screen and (max-width: 860px) {
  .containerProfile {
    padding: 5px;
  }

  .company-profile-text {
    width: 100%;
    padding: 20px;
  }

  .containerLi {
    margin-left: -30px;
    width: 120%;
    margin-bottom: 15px;
    flex-direction: column;

    .singkatan {
      width: 50%;
    }
  }

  .key-features {
    width: 100%;
  }

  .content-key-features,
  .content-visi-misi {
    width: 100%;
  }
}

@media (min-width: 14in) {
  .containerProfile {
    margin: 0 auto;
    max-width: 14in;
  }
}
