.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0 50px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  width: 100%;
  z-index: 99;
}

.navbarRight {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 85px;

  .nav-menu {
    display: flex;
    font-size: 0.9rem;
    font-weight: 600;
    list-style: none;
    height: 100%;

    // Mengganti li menjadi Link di CSS
    .nav-links {
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      position: relative;
      color: #ffffff;
      text-decoration: none;
      border-radius: 4px;
      padding: 8px;

      &:hover {
        color: #e6e6e6;
      }

      &::after {
        content: '';
        display: block;
        border-bottom: 3px solid #56a5ff;
        width: 0;
        bottom: 0;
        position: absolute;
        transition: width 0.3s ease-in-out;
      }

      &:hover::after {
        width: 100%;
      }

      span {
        display: flex;
        align-items: center;
      }

      & .dropdown-arrow {
        margin-left: 5px;
        transition: transform 0.3s ease-in-out;
        display: inline-block;
        font-size: 0.8rem;
      }

      // Define the rotation animation on hover
      &:hover .dropdown-arrow {
        transform: rotate(180deg);
      }
    }
  }
}

#languageContainer {
  margin-left: 20px;

  .span-menu {
    display: flex;

    gap: 5px;
    border-radius: 13px;
    padding: 8px 10px;
    background-color: rgba(145, 205, 255, 0.507);
  }
}

.languageDropdown {
  display: flex;
  flex-direction: column;
  min-width: 75px;
  padding: 10px;
  gap: 5px;

  .language-button-dropdown {
    display: flex;
    cursor: pointer;
    border: none;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 10px;
    padding: 5px 10px;
    transition: all ease-in-out 0.2s;

    p {
      margin: 0 auto;
    }

    &:hover {
      background-color: #d1d1d1;
      color: #ffffff;
    }
  }

  .language-button-dropdown.active {
    background-color: rgba(148, 148, 148, 0.507);
    color: white;
    border: none;
    transition: all 0.2s ease-in-out;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: max-content;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom: #56a5ff 5px solid;
  z-index: 1;
  animation: fadeUp 0.3s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    left: 15px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255) transparent;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-links:hover .dropdown-content {
  display: block; /* Show the dropdown when the nav-link is hovered */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: all ease-in-out 0.3s;
}

/* Style for the dropdown links on hover */
.dropdown-content a:hover {
  background-color: rgb(211, 211, 211);
}

.dropdown-links {
  position: relative;
}

.dropdown-links2 {
  position: relative;
  width: 40vh;

  .imgMenuLinks {
    background-image: url('../../assets/home/bg-nethome.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: max-content;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    .containerLogo {
      width: 85%;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10vh;

      img {
        width: 100%;
        height: 6vh;
      }
    }
  }

  .imgMenuLinks2 {
    background-image: url('../../assets/home/bg-tachyon.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: max-content;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    .containerLogo {
      width: 85%;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 10vh;

      img {
        width: 100%;
        height: 8vh;
      }
    }
  }
}

.dropdown-links::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #56a5ff;
  transition: width 0.3s ease-in-out;
}

.dropdown-links:hover::after {
  width: 100%;
}

.dropdown-links2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #56a5ff;
  transition: width 0.3s ease-in-out;
}

.dropdown-links2:hover::after {
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  filter: drop-shadow(0px 0px 5px rgb(255, 255, 255));
  padding: 15px 5px 5px 5px;
}

.navbar.scrolled {
  background-color: #ffffffda;
  border-radius: 15px;
  width: 98%;
  left: 1%;
  padding: 0 25px;
  transition: transform 0.7s ease-in-out;
  transform: translateY(15%);
}

.navbar.scrolled .nav-links {
  color: #0a5493;

  &:hover {
    color: #1c66a7;
  }
}

.fa-react {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  margin-top: -10px;
  margin-right: 15px;
}

.language {
  border-radius: 3px;
  border: 1px solid #bedafa;
}

.language-button {
  cursor: pointer;
  border: none;
  background-color: #fcfcfc00;
  font-size: 15px;
  border-radius: 2px;

  img {
    margin-bottom: 3px;
  }
}

.language-button.active {
  background-color: #bedafa;
  color: white;
  border: none;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 960px) {
  .navbar,
  .navbar.scrolled {
    background-color: #ffffff88;
    border-radius: 0;
    width: 100%;
    left: 0;
    padding: 0 25px;
    transition: transform 0.7s ease-in-out;
    transform: translateY(0%);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    padding: 0;
    top: 85px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);

    &.active {
      background-color: #1a6fd1;
      left: 0;
      opacity: 1;
      transition: all 0.7s ease;
      z-index: 9999999;
      display: flex;
      align-items: start;
      padding: 25px 20px;
      height: 75vh;
    }

    .nav-links {
      font-size: 1.2rem;
      display: flex;
      align-self: flex-start;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        background-color: #56a5ff;
        transition: width 0.3s ease-in-out;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }

  .navbar-logo {
    padding: 15px 5px 5px 0px;
  }

  .navbar.scrolled .nav-links {
    color: #cfcfcf;

    &:hover {
      color: #ffffff;
    }
  }

  .nav-links:hover {
    background-color: transparent;
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 3px;
    right: -10px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-bars {
    color: #0a3097;
  }

  .fa-times {
    color: #0a3097;
    font-size: 2rem;
    margin-right: 3px;
  }

  .language {
    width: max-content;
  }

  .navbar .language,
  .navbar.scrolled .language {
    margin-left: 5px;
    margin-top: 10px;

    .tooltipContainer {
      z-index: 9999;
    }
  }

  .language-item {
    display: flex;
    align-items: center;
    padding: 0 20px;
  }

  .navbar.scrolled .nav-menu::after,
  .nav-menu::after {
    display: none;
  }
}

@media (min-width: 14in) {
  .navbar {
    padding: 0 250px;
  }
  .navbar.scrolled {
    padding: 0 250px;
  }
}
