.mainFooter {
  background-color: #0a5493;
  color: #ececec;
  font-family: Arial, Helvetica, sans-serif;
  padding: 15px 15px 0 15px;
}

.stickyFooter {
  bottom: 0;
}

.mainFooter hr {
  height: 3px;
}

.footerTop {
  height: 45vh;
  display: flex;
  justify-content: space-between;
}

.footerBottom {
  display: flex;
  justify-content: center;
  text-align: center;
  color: #3f3f3f;
  font-weight: 500;
}

.footerBottom p {
  color: #ececec;
  font-size: 0.9rem;
}

.footerLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1.2;
  gap: 10px;
}

.footerLeft .twoLeft {
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.footerLeft .twoLeft p {
  margin: 0;
  font-size: 0.8rem;
}

.footerLeft .firstLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 50px;
  height: 50%;
  width: 30%;
  gap: 15px;

  .awardsImage {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;

    .awards {
      width: 33%;
      cursor: pointer;
      transition: all ease-in-out 0.3s;

      &:hover {
        border-radius: 50%;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.66);
        -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.66);
        -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.66);
      }
    }
  }
}

.footerLeft .firstLeft .logoFooter {
  width: 125%;
  filter: drop-shadow(0px 0px 3px rgb(255, 255, 255));
}

.borderLine {
  border: 1px solid rgb(255, 255, 255);
  height: 75%;
  margin: 3% 1%;
}

/* css footer midle */

.footerRight {
  flex: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.logoAcs {
  width: 120%;
  border-radius: 3px;
  filter: drop-shadow(0px 0px 2px rgb(255, 255, 255));
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    filter: drop-shadow(0px 0px 4px rgb(255, 255, 255));
  }
}

.footerRight .rightTop {
  text-align: center;
}

.footerRight .rightTop h3 {
  font-weight: 600;
  font-size: 1.2rem;
}

.rightBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.rightBottom a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 50%;
  color: #138cee;
  padding: 5px;
  font-size: 20px;
  background-color: white;
  transition: all ease-in-out 0.2s;
}

.rightBottom a .icon {
  font-size: 20px;
}

.rightBottom a:hover {
  color: white;
  background-color: #328fdb;
}

/* css footer right */

.footerMidle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex: 1.2;
  font-size: 87%;
  margin: 10px;
}

.footerMidle h6 {
  font-weight: 600;
}

.footerMidle a {
  margin-top: 5px;
  text-decoration: none;
  color: #ececec;
  transition: all ease-in-out 0.2s;
}

.footerMidle .tautan {
  display: flex;
  flex-direction: column;
}

.footerMidle .pengumuman {
  display: flex;
  flex-direction: column;
}

.footerMidle .laporan {
  display: flex;
  flex-direction: column;
}

.footerMidle .tautan a:hover,
.footerMidle .pengumuman a:hover,
.footerMidle .laporan a:hover {
  color: #328fdb;
}

.towLeft p {
  margin-bottom: 3px;
  font-size: 90%;
}

.towLeft h5 {
  margin-top: 25px;
}

/* Responsive */

@media (max-width: 860px) {
  .footerTop {
    flex-direction: column;
    align-items: center;
    height: max-content;
    gap: 30px;
  }

  .footerBottom {
    align-items: center;
    text-align: center;
  }

  .footerLeft {
    text-align: center;
    flex-direction: column;
    margin-top: 20px;

    .firstLeft {
      padding: 5px;
    }

    .twoLeft {
      padding: 0;
    }
  }

  .footerMidle {
    text-align: center;
    flex-direction: column;
    gap: 25px;
  }

  .borderLine {
    width: 70%;
    margin: 0;
  }
}

@media (min-width: 14in) {
  .footerTop {
    margin: 0 auto;
    max-width: 14in;
  }
}
