.containerKodeEtik {
  padding: 0 70px 0 70px;
}

.contentKodeEtik {
  border-radius: 100px;
  box-shadow: 0px 0px 20px 0px rgba(2, 2, 2, 0.253);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(2, 2, 2, 0.253);
  -moz-box-shadow: 0px 0px 20px 0px rgba(2, 2, 2, 0.253);
  height: max-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  border-radius: 100px;

  h5 {
    font-weight: 600;
    font-size: 1rem;
    align-self: flex-start;
  }

  ul,
  p {
    align-self: flex-start;
  }
}

@media screen and (max-width: 860px) {
  .containerKodeEtik {
    padding: 15px;
  }

  .contentKodeEtik {
    border-radius: 25px;
  }
}

@media (min-width: 14in) {
  .containerKodeEtik {
    margin: 0 auto;
    max-width: 14in;
  }
}
